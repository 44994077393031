import React, { Fragment } from "react"
import styled from "styled-components"
import { deviceMax } from "./../styles/mediaqueries"
import { fontweight } from "../styles/variables"
import { colors } from "../styles/colors"
import { toHTML } from "../helpers"

const StyledArticleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    @media ${deviceMax.laptopL} {
        padding: 0 5rem;
    }
    @media ${deviceMax.tablet} {
        padding: 0 2.5rem;
    }
    @media ${deviceMax.mobileL} {
        padding: 0 2rem;
    }
`

const StyledArticleOuterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${deviceMax.laptopL} {
        margin: 2rem 5rem;
    }
    @media ${deviceMax.laptopNarrow} {
        margin: 2rem 2rem;
    }
    @media ${deviceMax.laptopL} {
        margin: 2rem 0rem;
    }
`

const StyledArticleContent = styled.div`
    p {
        padding: 1rem 0;
        font-size: 18px;
        font-weight: ${fontweight.regular};
        color: ${colors.royalBlue};
        line-height: 36px;
        @media ${deviceMax.tablet} {
            font-size: 20px;
        }
        @media ${deviceMax.mobileL} {
            font-size: 20px;
            line-height: 31px;
        }
        @media ${deviceMax.mobileS} {
            font-size: 16px;
            line-height: 27px;
        }
    }

    h3 {
        padding: 2rem 0;
        font-size: 25px;
        font-weight: ${fontweight.bold};
        color: ${colors.royalBlue};
        @media ${deviceMax.tablet} {
            font-size: 24px;
        }
        @media ${deviceMax.mobileL} {
            font-size: 23px;
            line-height: 30px;
        }
        @media ${deviceMax.mobileS} {
            font-size: 18px;
            line-height: 25px;
        }
    }

    h4 {
        padding: 0;
        padding-top: 1rem;
        font-size: 20px;
        font-weight: ${fontweight.bold};
        color: ${colors.royalBlue};
        @media ${deviceMax.tablet} {
            font-size: 18px;
        }
        @media ${deviceMax.mobileL} {
            font-size: 16px;
            line-height: 30px;
        }
        @media ${deviceMax.mobileS} {
            font-size: 16px;
            line-height: 25px;
        }
    }
`

const StyledArticleContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
`

interface IAapenhetslovenContentProps {
    heading?: string
    subheading?: string
    content?: string
}

const AapenhetslovenContent: React.FC<IAapenhetslovenContentProps> = ({
    heading,
    subheading,
    content,
}: IAapenhetslovenContentProps) => {
    const contentHtml: any = toHTML(content)

    return (
        <Fragment>
            <StyledArticleOuterContainer>
                <StyledArticleWrapper>
                    <StyledArticleContentWrapper>
                        <p
                            style={{
                                color: colors.royalBlue,
                                fontSize: "18px",
                            }}
                        >
                            {subheading}
                        </p>
                        <StyledArticleContent
                            dangerouslySetInnerHTML={{ __html: contentHtml }}
                        ></StyledArticleContent>
                    </StyledArticleContentWrapper>
                </StyledArticleWrapper>
            </StyledArticleOuterContainer>
        </Fragment>
    )
}

export default AapenhetslovenContent
